import { track } from "./utils";

// Observe no-results
const noResultsCallback = function (mutationsList, observer) {
  for (const mutation of mutationsList) {
    if (mutation.type === "childList") {
      // Check added nodes
      mutation.addedNodes.forEach((node) => {
        if (
          node.nodeType === Node.ELEMENT_NODE &&
          node.classList.contains("yxt-AlternativeVerticals") &&
          node.classList.contains("yxt-AlternativeVerticals--notShowingResults")
        ) {
          let analyticsObj = track({ cbpgFunction: "searchresults-noresults"}, true);
          setTimeout(() => {
            const message = { iFrameCBDL: analyticsObj };
            window.parent.postMessage(message, '*');
          }, 1000);
        }
      });
    }
  }
};

const noResultsObserver = new MutationObserver(noResultsCallback);
noResultsObserver.observe(document.body, { childList: true, subtree: true });

// Observe results
const resultsCallback = function (mutationsList, observer) {
  for (const mutation of mutationsList) {
    if (mutation.type === "childList") {
      // Check added nodes
      mutation.addedNodes.forEach((node) => {
        if (
          node.nodeType === Node.ELEMENT_NODE &&
          node.classList.contains("yxt-Results") &&
          node.classList.contains("yxt-Results--advisors")
        ) {
          let analyticsObj = track({ cbpgFunction: "searchresults" }, true);
          setTimeout(() => {
            const message = { iFrameCBDL: analyticsObj };
            window.parent.postMessage(message, '*');
          }, 1000);
        }
      });
    }
  }
};

const resultsObserver = new MutationObserver(resultsCallback);
resultsObserver.observe(document.body, { childList: true, subtree: true });
